@font-face {
  font-family: "Mabry";
  src: url("/fonts/MabryPro/MabryPro-Regular.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Mabry";
  src: url("/fonts/MabryPro/MabryPro-Bold.otf");
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Brick";
  src: url("/fonts/BrickDisplay/BrickDisplayPro-Medium.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Mono";
  src: url("/fonts/Mono/DMMono-Light.ttf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Mono";
  src: url("/fonts/Mono/DMMono-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Mono";
  src: url("/fonts/Mono/DMMono-Medium.ttf");
  font-style: bold;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Source";
  src: url("/fonts/Source/SourceSansPro-ExtraLight.ttf");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Source";
  src: url("/fonts/Source/SourceSansPro-ExtraLightItalic.ttf");
  font-style: italic;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Source";
  src: url("/fonts/Source/SourceSansPro-Light.ttf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Source";
  src: url("/fonts/Source/SourceSansPro-LightItalic.ttf");
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Source";
  src: url("/fonts/Source/SourceSansPro-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Source";
  src: url("/fonts/Source/SourceSansPro-Italic.ttf");
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Source";
  src: url("/fonts/Source/SourceSansPro-SemiBold.ttf");
  font-style: bold;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Source";
  src: url("/fonts/Source/SourceSansPro-SemiBoldItalic.ttf");
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Source";
  src: url("/fonts/Source/SourceSansPro-Bold.ttf");
  font-style: bold;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Source";
  src: url("/fonts/Source/SourceSansPro-BoldItalic.ttf");
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Source";
  src: url("/fonts/Source/SourceSansPro-Black.ttf");
  font-style: bold;
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "Source";
  src: url("/fonts/Source/SourceSansPro-BlackItalic.ttf");
  font-style: italic;
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "Metro";
  src: url("/fonts/Metro/Metropolis-Thin.otf");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Metro";
  src: url("/fonts/Metro/Metropolis-ThinItalic.otf");
  font-style: italic;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Metro";
  src: url("/fonts/Metro/Metropolis-ExtraLight.otf");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Metro";
  src: url("/fonts/Metro/Metropolis-ExtraLightItalic.otf");
  font-style: italic;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Metro";
  src: url("/fonts/Metro/Metropolis-Light.otf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Metro";
  src: url("/fonts/Metro/Metropolis-LightItalic.otf");
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Metro";
  src: url("/fonts/Metro/Metropolis-Regular.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Metro";
  src: url("/fonts/Metro/Metropolis-Italic.otf");
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Metro";
  src: url("/fonts/Metro/Metropolis-Medium.otf");
  font-style: bold;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Metro";
  src: url("/fonts/Metro/Metropolis-MediumItalic.otf");
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Metro";
  src: url("/fonts/Metro/Metropolis-SemiBold.otf");
  font-style: bold;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Metro";
  src: url("/fonts/Metro/Metropolis-SemiBoldItalic.otf");
  font-style: italic;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Metro";
  src: url("/fonts/Metro/Metropolis-Bold.otf");
  font-style: bold;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Metro";
  src: url("/fonts/Metro/Metropolis-BoldItalic.otf");
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Metro";
  src: url("/fonts/Metro/Metropolis-ExtraBold.otf");
  font-style: bold;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "Metro";
  src: url("/fonts/Metro/Metropolis-ExtraBoldItalic.otf");
  font-style: italic;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "Metro";
  src: url("/fonts/Metro/Metropolis-Black.otf");
  font-style: bold;
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "Metro";
  src: url("/fonts/Metro/Metropolis-BlackItalic.otf");
  font-style: italic;
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato/Lato-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato/Lato-Thin.ttf");
  font-style: thin;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato/Lato-Bold.ttf");
  font-style: bold;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato/Lato-Black.ttf");
  font-style: bold;
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato/Lato-Light.ttf");
  font-style: light;
  font-weight: 300;
  font-display: swap;
}