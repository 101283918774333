$primary: #ff0000;
$secondary: #0000ff;

$border: #e6e6e6;

$dark: #151515;
$heading: #444444;
$desc: #808080;
$pdesc: #515960;
$gray: #515960;

$back: #f9f7f4;
$back2: #f6f6f7;

$leaf: #dae8d2;
$green: #064c35;
$green: #223a2a;
$gold: #e9ba52;
$beige: #F9ECD2;
$greige: #f4f2ed;
$yellow: #f8e92a;
$yellowLight: #fbf2d7;
$pink: #ffd4d4;
$peach: #ffbb9a;
$baby: #ecf8ff;
$orange: #fc954b;
$orangeLight: #fc954b;
$orangeDark: #d45e0a;

$blue: #16212a;
$cyan: #1755a0;

$warning: #ac2323;
$success: #228817;

$transition: all .3s ease;
$shadow: 0px 2px 6px -1px rgba(0,0,0,.15);
$shadow10: 0px 2px 6px -1px rgba(0,0,0,.1);
$shadow15: 0px 2px 6px -1px rgba(0,0,0,.15);
$shadow20: 0px 2px 6px -1px rgba(0,0,0,.20);
$radius: 24px;
$radiusS: 12px;
$radiusM: 36px;
$radiusL: 48px;