@import "vars.scss";
@import "vars.scss";
@import "fonts.scss";

:global(:root) {
	--primary-color: #1755a0;
	--secondary-color: #16212a;
	--third-color: #fc954b;
}

html {
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

html,
body {
	overflow-x: hidden;
	padding: 0;
	margin: 0;
	font-family: "Metro", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	color: #16212a;
	font-size: 16px;
	line-height: 1.65;
	font-weight: normal;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

ul,
li,
ol,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe {
	padding: 0;
	margin: 0;
}

.Brick,
.ql-font-Brick {
	font-family: "Brick", serif !important;
}

.Mabry {
	font-family: "Mabry", serif !important;
}

.Source,
.ql-font-Source {
	font-family: "Source", serif !important;
}

.Metro,
.ql-font-Metro {
	font-family: "Metro", serif !important;
}

.Mono {
	font-family: "Mono", serif !important;
}

.Domain {
	font-family: "Domain", serif !important;
}

*,
*::before,
*::after {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

:root {
	-moz-tab-size: 4;
	tab-size: 4;
}

small {
	font-size: 80%;
}

button,
input,
optgroup,
select,
textarea {
	font-family: "Metro", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	font-size: 100%;
	line-height: 1;
	margin: 0;
}

button,
select {
	text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
	-webkit-appearance: none;
}

b,
strong {
	font-weight: bold;
}

img,
embed,
iframe,
object,
audio,
video {
	height: auto;
	max-width: 100%;
	vertical-align: top;
}

.partnerIframe{
    width: 100% !important;
    height: 90% !important;
    border-radius: 25px;
	padding:20px;
	overflow: hidden;
    @media(max-width:767px){
        min-height: 400px !important;
		padding:0px
    }
}

video {
	max-height: 100vh;
}

.no-sel {
	user-select: none;
}

.top-list{
    li{
        margin:10px 0;
    }
}


h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 100%;
	margin-top: 0;
	font-weight: normal;
	line-height: normal;

	&.Cyan {
		color: $cyan;
	}

	&.Yellow {
		color: $yellow;
	}

	&.White {
		color: #fff;
	}

	&.Orange {
		color: $orange;
	}

	&.back {
		color: $back;
	}

	&.Greige {
		color: $greige;
	}

	&.Beige {
		color: $beige;
	}

	&.Peach {
		color: $peach;
	}
}

h6 {
	font-size: 16px;
}

.headingGroup {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 8px;
	}
}

p {
	color: $pdesc;
	font-weight: 400;
	line-height: 1.65;
	margin-top: 0;

	&.Cyan {
		color: $cyan;
	}

	&.White {
		color: #fff;
	}
}

a {
	color: inherit;
	text-decoration: none;
	cursor: pointer;

	&.White {
		color: #fff;
	}

	&:hover {
		text-decoration: underline;
	}

	&[disabled] {
		pointer-events: none;
	}
}

.ql-indent-1 {
	padding-left: 12px;
}

.ql-indent-2 {
	padding-left: 24px;
}

.ql-indent-3 {
	padding-left: 36px;
}

.ql-indent-4 {
	padding-left: 48px;
}

.ql-align-center {
	text-align: center !important;
}

.ql-align-right {
	text-align: right !important;
}

.or-divider {
	font-size: 13px;
	color: #999;
	display: flex;
	align-items: center;
	gap: 20px;
	font-weight: 500;
	margin: 12px 0;

	&:before,
	&:after {
		content: '';
		display: inline-block;
		height: 1px;
		width: 20%;
		background-color: #ddd;
		flex-grow: 1;
	}
}

.Heading {
	font-size: 22px;
	letter-spacing: 0px;
	font-weight: 600;
	letter-spacing: -.5px;

	@media (min-width: 768px) {
		font-size: 32px;
	}

	&__main {
		font-size: 40px;
		font-weight: 600;
	}

	&__main_small {
		font-size: 32px;
		font-weight: 600;
	}

	&__sub {
		font-size: 22px;
	}

	&__sub_small {
		font-size: 18px;
	}

	&__price {
		font-size: 22px;
		font-weight: bold;
		margin: 0;
	}

	&__price_small {
		font-size: 18px;
		font-weight: bold;
		margin: 0;
	}

	&__small {
		font-size: 16px;
	}

	&.Light {
		font-weight: 300;
	}

	&__styled {
		font-size: 16px;
		font-weight: 600;

		&:before {
			content: '';
			display: inline-block;
			height: 2px;
			width: 80px;
			background-color: #000;
			vertical-align: 4px;
			margin-right: 24px;
		}
	}
}

.hidden {
	display: none !important;
}

@media(max-width:767px) {
	.hidden-mobile {
		display: none !important;
	}
}

.relative {
	position: relative;
}

.title,
.Title {
	font-family: "Metro", serif;
	color: #fff;
	font-weight: 600;
	font-size: 17px;
	letter-spacing: .5px;
	line-height: 1.2;

	@media (min-width: 767px) {
		font-size: 20px;
	}

	&.Black {
		color: #000;
	}

	&.Green {
		color: #223a2a;
	}

	&32 {
		font-size: 32px;
	}
}

.h {
	&1 {
		font-size: 40px;
	}

	&2 {
		font-size: 22px;
	}

	&3 {
		font-size: 20px;
	}

	&4 {
		font-size: 18px;
	}

	&5 {
		font-size: 16px;
	}

	&6 {
		font-size: 14px;
	}
}

.Light {
	font-weight: 100;
}

.Medium {
	font-weight: 400;
}

.Bold {
	font-weight: bold;
}

.Bolder {
	font-weight: bolder;
}

.Rad,
.Radius {
	border-radius: $radius;

	&0 {
		border-radius: 0px !important;
	}

	&4,
	&XS {
		border-radius: 4px;
	}

	&A {
		border-radius: 4px !important;
	}

	&8,
	&S {
		border-radius: 8px;
	}

	&12,
	&M {
		border-radius: 12px;
	}
	&24,
	&L{
		border-radius: 24px;
	}

	&50,
	&XL {
		border-radius: 50px;
	}

	&50 {
		border-radius: 50px;
	}
}

.word-break {
	word-break: break-word;
}

.ofh {
	overflow: hidden;
}

.bRad {
	border-bottom-left-radius: $radius;
	border-bottom-right-radius: $radius;
}

.tRad {
	border-top-left-radius: $radius;
	border-top-right-radius: $radius;
}

.lRad {
	border-top-left-radius: $radius;
	border-bottom-left-radius: $radius;
}

.rRad {
	border-top-right-radius: $radius;
	border-bottom-right-radius: $radius;
}

.Shadow {
	box-shadow: $shadow !important;
}

.Circle {
	border-radius: 50%;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;

	&__sm {
		@media(max-width:991px) {
			text-align: center;
		}
	}

	&__xs {
		@media(max-width:767px) {
			text-align: center;
		}
	}
}

hr {
	height: 1px;
	background-color: $border;
	border: none;
	margin-top: 24px;
	margin-bottom: 24px;
}

button {
	white-space: nowrap;
	outline: 0;
	box-shadow: none;
	border: 0;
	cursor: pointer;
	background: transparent;
	-webkit-appearance: none;
	appearance: none;
}

form {
	position: relative;
}

label {
	display: block;
	font-size: 16px;
	margin-bottom: 8px;
	color: $heading;
	font-weight: 500;
}

.AdminContent label {
	font-weight: bold;
	font-size: 14px;
}

input:not([type=checkbox]):not([type=radio]):not([type="submit"]),
textarea {
	box-shadow: none;
	outline: 0;
	border: 1px solid $border;
	padding: 14px 18px;
	width: 100%;
	font-size: 16px;
	color: $dark;
	border-radius: $radius;
	-webkit-appearance: none;
	appearance: none;

	&:focus {
		border-color: darken($border, 10%);
	}

	&[type="checkbox"] {
		width: auto;
	}
}

input[type="submit"] {
	box-shadow: none;
	outline: 0;
	border: 1px solid $border;
	padding: 14px 12px;
	width: 100%;
	font-size: 16px;
	color: $dark;
	border-radius: $radius;
	-webkit-appearance: none;
	appearance: none;
	width: auto;
	min-width: 120px;
	border-color: $orange;
	background-color: $orange;
	color: #fff;

	&.disabled,
	&.Disabled,
	&[disabled],
	&[disabled=disabled] {
		background-color: #999 !important;
		border-color: #999 !important;
		cursor: not-allowed !important;
	}
}

textarea {
	display: block;
	resize: vertical;
	min-height: 80px;
}

.inputGroup {
	display: flex;
	margin-left: -6px;
	margin-right: -6px;
	flex-wrap: wrap;

	@media (min-width: 768px) {
		flex-wrap: nowrap;
	}

	.inputContainer {
		margin-left: 6px;
		margin-right: 6px;
		width: 100%;

		@media (min-width: 900px) {
			width: 50%;
		}

		input {}
	}
}

.inputContainer,
.InputContainer {
	position: relative;
	margin-bottom: 12px;

	&.noLabel {
		margin-bottom: 12px;

		label {
			position: absolute;
			pointer-events: none;
			font-size: 12px;
			top: 4px;
			left: 19px;
			opacity: 0;
			transition: all .25s linear;
			transition-delay: 0s;
		}

		input:not([type="submit"]) {
			transition: .2s ease-out;
			transition-delay: .3s;

			&:placeholder-shown {}

			&:not(:placeholder-shown) {
				padding-bottom: 6px;
				padding-top: 22px;
				transition-delay: 0s;
			}
		}

		input:not(:placeholder-shown)~label {
			opacity: 1;
			transition-delay: .3s;
		}
	}
	> div > div {
		border-radius: $radius;
		border-color: $border;
	}
	&.str8 {
		min-height: 46px;
		div, span {
			border-radius: 25px;
		}
		div {
			text-align: left;
			justify-content: left;
		}
		div > div > div {
			padding-left: 8px;
		}
	}
	&.error {
		input:not([type="submit"]) {
			color: $warning;
			border-color: $warning;
		}

		label {
			color: $warning;
		}
	}
}

.select__control {
	.select__value-container {
		padding: 0 10px !important;
	}

	.select__indicators {
		height: 48px;
	}
}

.inputDesc {
	display: block;
	width: 100%;
	background-color: $back;
	padding: 12px 12px;
	border-top: 1px dashed $border;

	p {
		margin: 0;
	}
}

.radioContainer {
	border: 1px solid #ddd;
	border-radius: $radius;
	background-color: #fff;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;

	>input {
		position: absolute;
		left: 12px;
	}

	>label {
		display: inline-block;
		flex-grow: 1;
		margin-bottom: 0;
		margin-left: 12px;
		padding: 12px;
	}

	>img {
		height: 20px;
		width: auto;
		position: absolute;
		top: calc((50px / 2) - 10px);
		right: 12px;
		pointer-events: none;
	}
}

.radioGroup {
	border: 1px solid #ddd;
	border-radius: $radius;
	overflow: hidden;

	.radioContainer {
		border: 0;
		border-radius: 0;
		border-bottom: 1px solid #ddd;

		&:last-of-type {
			border-bottom: 0;
		}

		.inputDesc {
			display: none;
		}

		input:checked~.inputDesc {
			display: block;
		}
	}
}

.checkContainer {
	margin-bottom: 12px;
	position: relative;
	text-align: left;
	display: flex;
	align-items: center;

	>input {
		flex-shrink: 0;
	}

	>label {
		white-space: nowrap;
		display: inline-block;
		margin-left: 12px;
		flex-grow: 1;
		margin-bottom: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;

		>input {
			margin-right: 12px;
		}
	}
}

.InputContainer.checkContainer {
	label {
		order: 2;
	}

	.child {
		order: 1;
	}

	.errorMsg {
		order: 10;
	}
}

@supports(-webkit-appearance: none) or (-moz-appearance: none) {

	input[type='checkbox'],
	input[type='radio'] {
		--active: #ffbb9a;
		--active-inner: #000000;
		--focus: 0px 0px 2px 1px rgba(40, 101, 108, .3);
		--border: #dddddd;
		--border-hover: #ffbb9a;
		--background: #fff;
		--disabled: #F6F8FF;
		--disabled-inner: #E1E6F9;
		-webkit-appearance: none;
		-moz-appearance: none;
		height: 21px;
		outline: none;
		display: inline-block;
		vertical-align: text-bottom;
		position: relative;
		margin: 0;
		cursor: pointer;
		border: 1px solid var(--bc, var(--border));
		background: var(--b, var(--background));
		transition: background .3s, border-color .3s, box-shadow .2s;

		&:after {
			content: '';
			display: block;
			left: 0;
			top: 0;
			position: absolute;
			transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
		}

		&:checked {
			--b: var(--active);
			--bc: var(--active);
			--d-o: .3s;
			--d-t: .6s;
			--d-t-e: cubic-bezier(.2, .85, .32, 1.2);
		}

		&:disabled {
			--b: var(--disabled);
			cursor: not-allowed;
			opacity: .9;

			&:checked {
				--b: var(--disabled-inner);
				--bc: var(--border);
			}

			&+label {
				cursor: not-allowed;
			}
		}

		&:hover {
			&:not(:checked) {
				&:not(:disabled) {
					--bc: var(--border-hover);
				}
			}
		}

		&:focus {
			box-shadow: var(--focus);
		}

		&:not(.switch) {
			width: 21px;
			min-width: 21px;

			&:after {
				opacity: var(--o, 0);
			}

			&:checked {
				--o: 1;
			}
		}
	}

	input[type='checkbox'] {
		&:not(.switch) {
			border-radius: $radius;

			&:after {
				width: 5px;
				height: 9px;
				border: 2px solid var(--active-inner);
				border-top: 0;
				border-left: 0;
				left: 7px;
				top: 4px;
				transform: rotate(var(--r, 20deg));
			}

			&:checked {
				--r: 43deg;
			}
		}

		&.switch {
			width: 38px;
			border-radius: 50px;

			&:after {
				left: 2px;
				top: 2px;
				border-radius: 50%;
				width: 15px;
				height: 15px;
				background: var(--ab, var(--border));
				transform: translateX(var(--x, 0));
			}

			&:checked {
				--ab: var(--active-inner);
				--x: 17px;
			}

			&:disabled {
				&:not(:checked) {
					&:after {
						opacity: .6;
					}
				}
			}
		}
	}

	input[type='radio'] {
		border-radius: 50%;

		&:after {
			width: 19px;
			height: 19px;
			border-radius: 50%;
			background: var(--active-inner);
			opacity: 0;
			transform: scale(var(--s, .7));
		}

		&:checked {
			--s: .5;
		}
	}
}

.success,
.success-text {
	color: $success !important;
}

.warning,
.warning-text {
	color: $warning !important;
}

.success-box {
	color: $success !important;
	padding: 8px 18px;
	border: 1px solid $success;
	background-color: #eaf3e9;
}

.warning-box {
	color: $warning !important;
	padding: 8px 18px;
	border: 1px solid $warning;
	background-color: rgba($warning, .1);
}

.disclaimer,
.disclaimer-text {
	font-size: 13px;
	color: $pdesc;

	a {
		color: #000;
	}
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.container,
.Container {
	padding-left: 24px;
	padding-right: 24px;
	max-width: 1260px;
	margin: 0 auto;

	@media (max-width: 767px) {
		padding-right: 16px;
		padding-left: 16px;
	}

	&.Narrow {
		max-width: 767px;
	}

	&__l {
		padding-left: 36px;
		padding-right: 36px;
	}

	&.center {
		text-align: center;
	}

	&.fullWidth,
	&.full-width {
		padding: 0;
		max-width: none;
	}
}

.section,
.Section {
	padding-bottom: 80px;

	@media (max-width: 767px) {
		padding-bottom: 60px;
	}
}

.bg {
	&White {
		background-color: #fff !important;
	}

	&Blue {
		background-color: $blue;
	}

	&Greige {
		background-color: $greige;
	}

	&Back {
		background-color: $back;
	}
}

.row {
	margin-left: -12px;
	margin-right: -12px;

	@media (max-width: 767px) {
		margin-left: -8px;
		margin-right: -8px;
	}

	&__l {
		margin-left: -24px;
		margin-right: -24px;

		@media (max-width: 767px) {
			margin-left: -12px;
			margin-right: -12px;
		}
	}

	&__xl {
		margin-left: -32px;
		margin-right: -32px;

		@media (max-width: 767px) {
			margin-left: -16px;
			margin-right: -16px;
		}
	}

	&.Scroll {
		@media (max-width: 767px) {
			margin-left: -16px;
			margin-right: -16px;
		}

		@media (min-width: 768px) and (max-width: 991px) {
			margin-left: -24px;
			margin-right: -24px;
		}

		&:not(.fullWidth)>*:first-of-type {
			@media (max-width: 767px) {
				margin-left: 8px;
			}

			@media (min-width: 768px) and (max-width: 991px) {
				margin-left: 12px;
			}
		}

		>*:last-of-type {
			@media (max-width: 767px) {
				margin-right: 8px;
			}

			@media (min-width: 768px) and (max-width: 991px) {
				margin-right: 12px;
			}
		}
	}

	&.modalScroll {
		margin-left: -24px;
		margin-right: -24px;

		>*:first-of-type {
			margin-left: 24px;
		}

		>*:last-of-type {
			margin-right: 24px;
		}
	}
}

.scrollParent {
	position: relative;

	&.cover {
		&:before {
			content: "";
			position: absolute;
			right: 100%;
			height: 100%;
			top: 0;
			bottom: 0;
			width: calc(((100vw - 100%) / 2) + 0px);
			z-index: 2;
			background: #fff;
		}

		&:after {
			content: "";
			position: absolute;
			left: 100%;
			height: 100%;
			top: 0;
			bottom: 0;
			width: calc(((100vw - 100%) / 2) + 0px);
			z-index: 1;
			background: #fff;
		}
	}

	&.fade {
		&:before {
			content: "";
			position: absolute;
			right: calc(100% + 16px);
			height: 100%;
			top: 0;
			bottom: 0;
			width: calc(((100vw - 100%) / 2) + 0px);
			z-index: 2;
			background: #fff;
			background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
			background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
		}

		&:after {
			content: "";
			position: absolute;
			left: calc(100% + 16px);
			height: 100%;
			top: 0;
			bottom: 0;
			width: calc(((100vw - 100%) / 2) + 0px);
			z-index: 1;
			background: #fff;
			background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
			background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
		}
	}
}

.Scroll {

	position: relative;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	white-space: nowrap;
	flex-wrap: nowrap !important;
	overflow: auto;
	-ms-overflow-style: none;
	overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	>* {
		white-space: normal;
	}

	&.fullWidth {
		width: 100vw;
		margin-left: calc((100% - 100vw) / 2);
		padding-left: calc(((100vw - 100%) / 2) - 12px);
		padding-right: calc(((100vw - 100%) / 2) - 12px);

	}

	.rowItemScroll {
		min-width: 60vw;

		@media (min-width: 768px) {
			min-width: 40vw;
		}

		@media (min-width: 992px) {
			min-width: 0;
		}

		&__S {
			min-width: 135px;
			width: 25%;
			max-width: 300px;

			@media (min-width: 992px) {
				min-width: 165px;
			}
		}

		&__M {
			width: auto;
			min-width: calc(100% / 1.5);

			@media (min-width: 768px) {
				min-width: calc(100% / 4.5);
			}

			@media (min-width: 1024px) {
				min-width: calc(100% / 5.5);
			}

			@media (min-width: 1170px) {
				min-width: calc(100% / 6.5);
			}
		}

		&__L {
			width: auto;
			min-width: calc(100% / 1.5);

			@media (min-width: 768px) {
				min-width: calc((100vw - ((100vw - 100%) / 2)) / 3.5);
				max-width: calc((100vw - ((100vw - 100%) / 2)) / 3.5);
			}

			@media (min-width: 1024px) {
				min-width: calc((100vw - ((100vw - 100%) / 2)) / 4.5);
				max-width: calc((100vw - ((100vw - 100%) / 2)) / 4.5);
			}

			@media (min-width: 1170px) {
				min-width: calc((100vw - ((100vw - 100%) / 2)) / 4.5);
				max-width: calc((100vw - ((100vw - 100%) / 2)) / 4.5);
			}
		}
	}

	&.i-4 {
		.rowItemScroll {
			&__L {
				width: auto;
				min-width: calc(100% / 1.5);

				@media (min-width: 768px) {
					min-width: calc((100vw - ((100vw - 100%) / 2)) / 3.5);
				}

				@media (min-width: 1024px) {
					min-width: calc(25% - 24px);
				}

				@media (min-width: 1170px) {
					min-width: calc(25% - 24px);
				}
			}
		}
	}
}

.GalleryImageWrapper {

	span,
	span img,
	span span img {
		display: block !important;
	}
}

.scrollBtnHolder {
	position: absolute;
	pointer-events: none;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	right: 0;
	height: 30px;
	z-index: 123;
}

.scrollBtn {
	outline: 0;
	cursor: pointer;
	background-color: transparent;
	touch-action: manipulation;
	position: absolute;
	z-index: 1;
	top: 50%;
	transform: translateY(-50%);
	border: 0;
	width: 30px;
	height: 30px;
	justify-content: center;
	align-items: center;
	fill: #000;
	padding: 0;
	background: #fff;
	border-radius: 100%;
	padding: 8px;
	pointer-events: all;
	z-index: 1234;
	box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, .2);
	display: none;
	background-size: 12px;
	background-repeat: no-repeat;
	background-position: center center;

	&.show {
		display: block;
	}

	&.show2 {
		display: block !important;
	}

	&.hide {
		display: none !important;
	}

	&.hide2 {
		display: none;
	}

	&.scrollLeft {
		left: 0;
		background-image: url(/img/icons/chevronLeft-black.svg);
	}

	&.scrollRight {
		left: auto;
		right: 0;
		background-image: url(/img/icons/chevronRight-black.svg);
	}
}

.rowItem {
	padding: 12px;

	@media (max-width: 767px) {
		padding: 8px;
	}

	&__l {
		padding: 24px;

		@media (max-width: 767px) {
			padding: 12px;
		}
	}

	&__xl {
		padding: 36px;

		@media (max-width: 767px) {
			padding: 18px;
		}
	}

	&.rowItem25 {
		width: 50%;

		@media (min-width: 768px) {
			width: 50%;
		}

		@media (min-width: 992px) {
			width: 25%;
		}
	}

	&.rowItem50 {
		width: 50%;

		@media (min-width: 768px) {
			width: 50%;
		}
	}

	&.rowItem33 {
		width: 33.33333%;
	}

	&.rowMobile100 {
		@media(max-width:767px) {
			width: 100% !important;
		}
	}
}

.p {
	&0 {
		padding: 0 !important;
	}

	&12 {
		padding: 12px;
	}

	&16 {
		padding: 16px;
	}

	&24 {
		padding: 24px;
	}

	&32 {
		padding: 32px;
	}
}

.pb {
	&100 {
		padding-bottom: 100%;
	}
}

.px {
	&0__m {
		@media (max-width: 767px) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&18 {
		padding-left: 18px;
		padding-right: 18px;
	}

	&24 {
		padding-left: 24px;
		padding-right: 24px;
	}

	&32 {
		padding-left: 32px;
		padding-right: 32px;
	}

	&36 {
		padding-left: 36px;
		padding-right: 36px;
	}
}

.py {
	&8 {
		padding-top: 8px;
		padding-bottom: 8px;
	}

	&12 {
		padding-top: 12px;
		padding-bottom: 12px;
	}

	&18 {
		padding-top: 18px;
		padding-bottom: 18px;
	}

	&24 {
		padding-top: 24px;
		padding-bottom: 24px;
	}

	&36 {
		padding-top: 36px;
		padding-bottom: 36px;
	}
}

.m {
	&0_reg {
		margin: 0;
	}

	&0 {
		margin: 0 !important;
	}

	&12 {
		margin: 12px;
	}

	&__auto {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
}

.my {
	&8 {
		margin-top: 8px;
		margin-bottom: 8px;
	}

	&12 {
		margin-top: 12px;
		margin-bottom: 12px;
	}

	&18 {
		margin-top: 18px;
		margin-bottom: 18px;
	}

	&24 {
		margin-top: 24px;
		margin-bottom: 24px;
	}

	&32 {
		margin-top: 32px;
		margin-bottom: 32px;
	}
}

.mt {
	&0 {
		margin-top: 0px !important;
	}

	&00 {
		margin-top: 0px !important;
	}

	&8 {
		margin-top: 8px;
	}

	&12 {
		margin-top: 12px;
	}

	&16 {
		margin-top: 16px;
	}

	&18 {
		margin-top: 18px;
	}

	&24 {
		margin-top: 24px;
	}

	&32 {
		margin-top: 32px;
	}
}

.mb {
	&0 {
		margin-bottom: 0px;
	}

	&00 {
		margin-bottom: 0px !important;
	}

	&8 {
		margin-bottom: 8px;
	}

	&12 {
		margin-bottom: 12px;
	}

	&16 {
		margin-bottom: 16px;
	}

	&18 {
		margin-bottom: 18px;
	}

	&24 {
		margin-bottom: 24px;
	}

	&32 {
		margin-bottom: 32px;
	}
}

.mr {
	&0 {
		margin-right: 0px;
	}

	&8 {
		margin-right: 8px;
	}

	&12 {
		margin-right: 12px;
	}

	&18 {
		margin-right: 18px;
	}

	&24 {
		margin-right: 24px;
	}

	&32 {
		margin-right: 32px;
	}
}

.ml {
	&0 {
		margin-left: 0px;
	}

	&8 {
		margin-left: 8px;
	}

	&12 {
		margin-left: 12px;
	}

	&18 {
		margin-left: 18px;
	}

	&24 {
		margin-left: 24px;
	}

	&32 {
		margin-left: 32px;
	}
}

.h100 {
	height: 100%;
}

.max-100vh {
	max-height: 100vh;
	overflow: hidden;
}

.b-white {
	background-color: #fff;
}

.b-black {
	background-color: #000;
}

.b-back {
	background-color: $back;
}

.b-blue {
	background-color: $blue;
}

.b-cyan {
	background-color: $cyan;
}

.b-yellow {
	background-color: $yellow;
}

.b-yellowLight {
	background-color: $yellowLight;
}

.b-beige {
	background-color: $beige;
}

.b-leaf {
	background-color: $leaf;
}

.b-peach {
	background-color: $peach;
}

.b-greige {
	background-color: $greige;
}

.b-pink {
	background-color: $pink;
}

.block {
	display: block !important;
}

.flex {
	display: flex;
}

.flex-row {
	flex-direction: row;
}

.row-reverse {
	flex-direction: row-reverse;

	&__d {
		flex-direction: row-reverse;

		@media (max-width: 768px) {
			flex-direction: row;
		}
	}

	&__m {
		flex-direction: row;

		@media (max-width: 768px) {
			flex-direction: column-reverse;
		}
	}
}

.flex-column {
	flex-direction: column;
}

.flex-wrap {
	flex-wrap: wrap;
}

.space-between {
	justify-content: space-between;
}

.space-evenly {
	justify-content: space-evenly;
}

.space-around {
	justify-content: space-around;
}

.justify-center {
	justify-content: center;
}

.justify-sm-center {
	@media(max-width:991px) {
		justify-content: center;
	}
}

.flex-end {
	justify-content: flex-end;
}

.align-center {
	align-items: center;
}

.flex-grow {
	flex-grow: 1;
}

.align-self-start {
	align-self: start;
}

.mob-only {
	@media (min-width: 768) {
		display: none !important;
	}
}

.tab-up {
	@media (max-width: 767px) {
		display: none !important;
	}
}

.tab-down {
	@media (min-width: 768px) {
		display: none !important;
	}
}

.tab-only {
	@media (max-width: 767px) {
		display: none !important;
	}

	@media (min-width: 1025) {
		display: none !important;
	}
}

.desc-only {
	@media (max-width: 1024px) {
		display: none !important;
	}
}

.split-item-50 {
	width: 50%;

	&__d {
		width: 100%;

		@media (min-width: 769px) {
			width: 50%;
		}
	}
}

.split-item-33 {
	width: calc(100% / 3);

	&__d {
		width: calc(100% / 3);

		@media (max-width: 768px) {
			width: 100%;
		}
	}
}

.split-item-66 {
	width: calc((100% / 3) * 2);

	&__d {
		width: calc((100% / 3) * 2);

		@media (max-width: 768px) {
			width: 100%;
		}
	}
}

/*
.select__menu {
	@media (max-width: 767px) {
		position: fixed !important;
	    top: auto !important;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    z-index: 12345;
	    border-bottom-left-radius: 0 !important;
	    border-bottom-right-radius: 0 !important;
	    margin: 0 !important;
	}
	.select__menu-list {
		@media (max-width: 767px) {
			max-height: 400px !important;
			padding-bottom: 50px;
		}
		.select__option {
			@media (max-width: 767px) {
				padding-top: 18px;
				padding-bottom: 18px;
			}
		}
	}
}
*/
table {
	width: 100%;
	border-spacing: 0;

	a {
		color: $cyan;
	}
}

th {
	text-align: left;
}

tr {

	td {

		&:first-of-type {}

		&:nth-of-type(2) {}
	}

	th {

		&:first-of-type {}

		&:nth-of-type(2) {}
	}
}

.imgRes {
	height: 100%;
	position: relative;

	>div {
		height: 100%;
	}

	>span {
		height: 100% !important;
	}

	&.imgResPage {
		min-height: 50vh;

		>img {
			position: absolute;
			object-fit: cover;
			height: 100%;
			object-position: center center;
			width: 100%;
		}

		&.posTop>img {
			object-position: center top;
		}
	}
}

.extraMenu {
	ul li a.active {
		color: #000;
		font-weight: bolder;
	}
}

.menuHolder {
	ul li a.active {
		@media (min-width: 1081px) {
			color: #000;
			font-weight: bolder;
		}

		&:before {
			@media (max-width: 1080px) {
				content: '|';
				margin-right: 16px;
			}
		}
	}
}

.sideMenu {
	ul {
		li {
			a {
				&.active {
					color: $orange !important;
					font-weight: normal !important;

					+.subMenu {
						display: block;
						position: relative;
						left: auto;
					}
				}
			}

			ul li a.active {
				color: $orange !important;
				font-weight: bolder !important;
				opacity: 1;
			}
		}
	}
}

button,
.Button {

	&.disabled,
	&.Disabled,
	&[disabled],
	&[disabled=disabled] {
		background-color: #999 !important;
		border-color: #999 !important;
		cursor: not-allowed !important;
	}
}

.Button {
	white-space: nowrap;
	display: inline-block;
	box-shadow: none;
	outline: 0;
	background-color: transparent;
	border: 1px solid transparent;
	color: $blue;
	padding: 14px 24px;
	font-size: 14px;
	font-family: "Source";
	line-height: 1;
	cursor: pointer;
	text-decoration: none;
	text-align: center;
	border-radius: $radius;

	&.SubmitButton {
		min-height: 46px;
		min-width: 120px;

		.Submitting {}
	}

	svg {
		margin-left: 8px;
	}

	&.Icon {
		width: 46px;
		min-width: 46px;
		padding: 14px 14px;

		svg {
			margin: 0;
		}
	}

	&.Full {
		width: 100%;
	}

	&.Primary {
		background-color: $peach;
		border-color: $peach;
		color: #000000;
		padding: 14px 24px;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0px;
	}

	&.Small {
		padding: 10px 16px;
	}

	&.Wide {
		padding-right: 36px;
		padding-left: 36px;
	}

	&.Cyan {
		background-color: $cyan;
		border-color: $cyan;
		color: #fff;
	}

	&.Black {
		background-color: #000;
		border-color: #000;
		color: #fff;
	}

	&.White {
		background-color: #fff;
		border-color: #fff;
		color: #000;
	}

	&.Blue {
		background-color: $blue;
		border-color: $blue;
		color: #fff;
	}

	&.Orange {
		background-color: $orange;
		border-color: $orange;
		color: #fff;
	}

	&.Solid {
		border-width: 2px !important;
		font-weight: bold;
	}

	&.Yellow {
		background-color: $yellow;
		border-color: $yellow;
		color: $dark;
	}

	&.Green {
		background-color: $green;
		border-color: $green;
		color: #fff; 
	}

	&.Gold {
		background-color: $gold;
		border-color: $gold;
	}

	&.Leaf {
		background-color: $leaf;
		border-color: $leaf;
		color: $blue;
	}

	&.Beige {
		background-color: $beige;
		border-color: $beige;
		color: #fff;
	}

	&.Peach {
		background-color: $peach;
		border-color: $peach;
		color: #fff;
	}

	&.Baby {
		background-color: $baby;
		border-color: $baby;
		color: $dark;
	}

	&.Pink {
		background-color: $pink;
		border-color: $pink;
		color: $dark;
	}

	&.Cancel {
		border-color: $pdesc;
		background-color: $pdesc;
		color: #fff;
	}

	&.Hollow {
		background-color: transparent !important;
		color: $blue;
		border-color: $blue;

		&.Cyan {
			color: $cyan;
			border-color: $cyan;
		}

		&.White {
			color: #fff;
			border-color: #fff;
		}

		&.Green {
			background-color: transparent;
			color: $green;
			border-color: $green;
		}

		&.Beige {
			color: $beige;
			border-color: $beige;
		}

		&.Peach {
			color: $peach;
			border-color: $peach;
		}

		&.b-white {
			background-color: #fff !important;
		}
	}

	&.leftIcon {
		svg {
			margin-left: 0;
			margin-right: 8px;
		}
	}

	&:hover {
		text-decoration: none;
	}

	&:active {
		transform: scale(0.9);
	}

	&.noFocus {
		&:active {
			transform: none !important;
		}
	}
}

.ButtonLink {
	&.Blank {
		background-color: transparent;
		border: 0;
	}

	&:hover {
		text-decoration: underline;
	}

	&.Primary {
		&:hover {
			text-decoration: none;
		}
	}
}

.ButtonGroup {

	button,
	.Button,
	a {
		margin-right: 12px;
		margin-bottom: 12px;
	}

	:last-child {
		margin-right: 0;
	}
}

.ButtonGroupFixed {
	border: 1px solid $border;
	border-radius: $radius;
	display: flex;
	margin-top: 16px;

	.Button {
		margin: 0;
		border: 0;
		flex-grow: 1;
		padding: 18px 24px;
		border-right: 1px solid $border;

		&:last-of-type {
			border: 0;
		}
	}
}

.favBtn {
	position: relative;
	height: 48px;
	width: 48px;
	cursor: pointer;
	background-image: url(/img/icons/like.svg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 20px;
	z-index: 123;

	&.active,
	&.Active,
	&.in-list {
		background-image: url(/img/icons/likeFull.svg);
	}

	&:hover {
		background-image: url(/img/icons/likeFull.svg);
	}

}

.favList {
	display: none;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 123;
	border-top-left-radius: $radius;
	border-top-right-radius: $radius;
	overflow: hidden;
	text-align: left;

	@media (min-width: 767px) {
		border-radius: calc($radius / 2);
		position: absolute;
		top: 12px;
		right: 12px;
		bottom: auto;
		left: auto;
		width: calc(100% - 24px);
	}


	&.open {
		display: block;
		z-index: 123456789;
	}

	.favListHeader {
		background-color: $back;
		padding: 18px 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
		border-bottom: 1px solid $border;

		@media (min-width: 767px) {
			padding: 8px 12px;
		}

		b {
			>span {
				@media (min-width: 767px) {
					display: none;
				}
			}
		}

		button {
			height: 24px;
			width: 24px;
			background-color: transparent;
			background-image: url(/img/icons/close-solid.svg);
			background-size: 14px;
			background-repeat: no-repeat;
			background-position: right center;
			padding: 12px 16px;
		}
	}

	.favListOverlay {
		position: fixed;
		background-color: #000;
		opacity: .5;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: -1;
	}

	ul {
		background-color: #fff;
		max-height: calc(100vh - 220px);
		overflow: auto;

		@media (min-width: 767px) {
			max-height: 230px;
		}

		li {
			font-size: 16px;
			border-bottom: 1px dashed $border;

			@media (min-width: 767px) {
				padding: 0;
				font-size: 14px;
			}
		}
	}

	.checkContainer {
		border-bottom: 1px solid $border;
		margin: 0;

		label {
			font-size: 14px;
			padding: 24px 16px;
			margin-left: 0 !important;

			@media (min-width: 767px) {
				padding: 12px;
			}
		}
	}

	button {
		position: relative;
		margin: 0px;
		padding: 24px 16px;
		border: 0;
		font-size: 16px;
		background-color: $cyan;
		color: #fff;
		font-weight: bold;

		@media (min-width: 767px) {
			padding: 18px 12px;
			font-size: 14px;
			font-weight: normal;
			background-color: $back;
			color: #000;
		}
	}
}

.infoBtn {
	position: relative;
	height: 48px;
	width: 48px;
	cursor: pointer;
	background-image: url(/img/icons/info-circle-light.svg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 20px;
	z-index: 123;
	filter: invert(1);

	&.open {
		background-image: url(/img/icons/info-circle-solid.svg);
	}

	&:hover {
		background-image: url(/img/icons/info-circle-solid.svg);
	}
}

.infoList {
	display: none;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 123;
	border-top-left-radius: $radius;
	border-top-right-radius: $radius;
	overflow: hidden;
	text-align: left;

	@media (min-width: 767px) {
		border-radius: calc($radius / 2);
		position: absolute;
		top: 12px;
		right: 12px;
		bottom: auto;
		left: auto;
		width: calc(100% - 24px);
	}

	&.open {
		display: block;
		z-index: 123456789;
	}

	.infoListHeader {
		background-color: $back;
		padding: 18px 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
		border-bottom: 1px solid $border;

		@media (min-width: 767px) {
			padding: 8px 12px;
		}

		b {
			>span {
				@media (min-width: 767px) {
					display: none;
				}
			}
		}

		button {
			height: 24px;
			width: 24px;
			background-image: url(/img/icons/close-solid.svg);
			background-size: 14px;
			background-repeat: no-repeat;
			background-position: right center;
		}
	}

	.infoListOverlay {
		position: fixed;
		background-color: #000;
		opacity: .5;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: -1;
	}

	ul {
		padding-bottom: 36px;
		background-color: #fff;
		max-height: calc(100vh - 220px);
		overflow: auto;

		@media (min-width: 767px) {
			padding-bottom: 0;
		}

		li {
			font-size: 14px;
			padding: 18px 12px;
			border-bottom: 1px dashed $border;

			@media (min-width: 767px) {
				padding: 8px 12px;
			}

			&:last-of-type {
				border-bottom: 0;
			}
		}
	}
}

.backBtn {
	height: 48px;
	width: 48px;
	cursor: pointer;
	background-image: url(/img/icons/chevronLeft.svg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 20px;
	z-index: 123;

	&:hover {
		background-image: url(/img/icons/chevronLeft.svg);
	}
}

.backBtnStyled {}

.ImageFilter {
	position: relative;

	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #000;
		pointer-events: none;
		opacity: .2;
	}

	&.White {
		&:after {
			background-color: #fff;
		}
	}
}

.appDisplay {
	display: flex;
	justify-content: center;

	>a {
		display: block;
		margin: 8px;

	}
}

.shareHolder {
	display: flex;
	padding: 18px 0;
}

.shareBtn {
	display: block;
	width: 20px;
	height: 20px;
	margin-right: 12px;
	fill: $cyan;

	&:last-of-type {
		margin-right: 0;
	}
}

.SortSelect {
	min-width: 120px;
}

.Page {
	p {
		color: #333;
	}
}

#modal-root {
	z-index: 999999999;
	position: relative;
}

.scrollElement {
	-webkit-overflow-scrolling: touch;
}

.react-tabs__tab {
	cursor: pointer;
}

.wistia_responsive_padding {
	padding: 42% 0 0 0;
	position: relative;
}

.wistia_responsive_wrapper {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.wistia_embed,
.wistia_embed.wistia_async_2671q0bukd {
	height: 100%;
	position: relative;
	width: 100%
}

.Post {

	.wistia_responsive_padding,
	.wistia_responsive_wrapper,
	.wistia_embed {
		display: none !important;
	}
}

.leaflet-control-attribution.leaflet-control {
	display: none;
}

.text-editor {
	width: 100%;
	margin: 0 auto;
}

.ql-editor {
	min-height: 100px;
	max-height: calc(100vh - 150px);
}

.ql-container {
	font-family: "Metro" !important;
	font-size: 16px !important;
}

.ql-toolbar.ql-snow {
	font-family: "Metro" !important;
}

.ql-editor.ql-blank::before {
	font-style: normal !important;
}

.draggableRow .listEditor .ql-editor {
	min-height: 0;
}

.draggableRow .text-editor>div>span.ql-formats {
	display: none;
}

.partner-page{
	margin-top:0 !important
}
.partner-page header.homeMenu,
.partner-page footer.Footer>div:nth-child(1){
    display: none !important;
}